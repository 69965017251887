import Layout from "../components/layout"
import { Link, useStaticQuery, graphql, useScrollRestoration } from "gatsby"
import Hero from "../components/hero"
import React, { Component } from "react"
import BookingForm from "../components/booking-form"
import Reviews from "../components/reviews"
import SEO from "../components/seo"
import * as ServicesStyles from "../styles/services.module.scss"
import OneScreenBookingForm from "../components/oneScreenBookingForm"

const ServicesPage = () => {
  const data = useStaticQuery(graphql`
    query ServicesQuery {
      bannerImage: cloudinaryMedia(
        public_id: {
          eq: "pivot-painters-chicago/portfolio/pivot-painters-1433-north-cleveland-ave-chicago-12"
        }
      ) {
        secure_url
      }
      interiorImage: cloudinaryMedia(
        public_id: {
          eq: "pivot-painters-chicago/portfolio/pivot-painters-1107-north-sacramento-chicago-13"
        }
      ) {
        secure_url
      }
      exteriorImage: cloudinaryMedia(
        public_id: { eq: "pivot-painters-chicago/exteriorSlideRight" }
      ) {
        secure_url
      }
      cabinetsImage: cloudinaryMedia(
        public_id: {
          eq: "pivot-painters-chicago/pivot-painters-3636-west-dickens-avenue-chicago-23"
        }
      ) {
        secure_url
      }
    }
  `)
  return (
    <Layout>
      <SEO
        title="Services"
        description="Pivot Painters - Chicago Painting Services"
      />
      <section className={ServicesStyles.servicesContainer}>
        <Hero name="Painting Services" src={data.bannerImage.secure_url} />
        <section
          className={`${ServicesStyles.service} ${ServicesStyles.oddRowService}`}
        >
          <Link to="/interior-painting-chicago">
            <h2 className={ServicesStyles.serviceTitle}>Interior Painting</h2>
          </Link>
          <div className={ServicesStyles.serviceInfo}>
            <div className={ServicesStyles.serviceImageContainer}>
              <Link to="/interior-painting-chicago">
                <img
                  src={data.interiorImage.secure_url}
                  alt="Pivot Painters - Painted Living Room"
                />
              </Link>
            </div>
            <div className={ServicesStyles.serviceDescriptionContainer}>
              <p>
                <strong>
                  Interior Painting helps make a house feel more like a home.
                </strong>{" "}
                A fresh coat of paint can redefine a space and change the look
                of a room. Pivot Painters are experts in interior painting and
                help transform homes of new movers, homeowners, and property
                managers alike.{" "}
                <Link to="/request-a-quote">
                  <strong>
                    Schedule Your Free Interior Painting Estimate Today!
                  </strong>
                </Link>
              </p>
              <div className={ServicesStyles.learnMoreButtonContainer}>
                <Link to="/interior-painting-chicago">
                  <button className={ServicesStyles.learnMoreButton}>
                    Learn More
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </section>
        <section className={ServicesStyles.service}>
          <Link to="/exterior-painting">
            <h2 className={ServicesStyles.serviceTitle}>Exterior Painting</h2>
          </Link>
          <div className={ServicesStyles.serviceInfo}>
            <div className={ServicesStyles.serviceDescriptionContainer}>
              <p>
                Exterior painting is one of the best ways you can{" "}
                <strong>increase your home's value and curb appeal.</strong>{" "}
                Painting the outside of your home can also help with long-term
                care and protection from the harsh weather. Whether you have
                aluminum siding, stucco, or just need the trim touched up, our
                experienced painting crews will get the job done.{" "}
                <Link to="/request-a-quote">
                  <strong>
                    Schedule Your Free Exterior Painting Estimate Today!
                  </strong>
                </Link>
              </p>
              <div className={ServicesStyles.learnMoreButtonContainer}>
                <Link to="/exterior-painting-chicago">
                  <button className={ServicesStyles.learnMoreButton}>
                    Learn More
                  </button>
                </Link>
              </div>
            </div>
            <div className={ServicesStyles.serviceImageContainer}>
              <Link to="/exterior-painting">
                <img
                  src={data.exteriorImage.secure_url}
                  alt="Pivot Painters - Exterior of A Home"
                />
              </Link>
            </div>
          </div>
        </section>
        <section
          className={`${ServicesStyles.service} ${ServicesStyles.oddRowService}`}
        >
          <Link to="/cabinet-refinishing-chicago">
            <h2 className={ServicesStyles.serviceTitle}>Cabinet Painting</h2>
          </Link>
          <div className={ServicesStyles.serviceInfo}>
            <div className={ServicesStyles.serviceImageContainer}>
              <Link to="/cabinet-refinishing-chicago">
                <img
                  src={data.cabinetsImage.secure_url}
                  alt="Pivot Painters - Kitchen Photo of Painted Cabients"
                />
              </Link>
            </div>
            <div className={ServicesStyles.serviceDescriptionContainer}>
              <p>
                Replacing your cabinets can be very expensive and time
                consuming.{" "}
                <strong>
                  Painting your cabinets is a much more cost effective
                  alternative that can leave you with the same beautiful finish
                  as replacing them.
                </strong>{" "}
                Painting kitchen cabinets requires you to follow a very
                technical process and use high-grade products to avoid chipping
                and peeling which Pivot Painters prides ourselves on.{" "}
                <Link to="/request-a-quote">
                  <strong>
                    Schedule Your Free Cabinet Painting Estimate Today!
                  </strong>
                </Link>
              </p>
              <div className={ServicesStyles.learnMoreButtonContainer}>
                <Link to="/cabinet-refinishing-chicago">
                  <button className={ServicesStyles.learnMoreButton}>
                    Learn More
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </section>
        <Reviews></Reviews>
        <OneScreenBookingForm></OneScreenBookingForm>
      </section>
    </Layout>
  )
}

export default ServicesPage
